.float-label {
    position: relative;
    // margin-bottom: 12px;
    .ant-input-group-wrapper + label{
      left: 80px;

    }
    .label {
      font-size: 12px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 15px;
      top: 12px;
      background: #fff;
      transition: 0.2s ease all;
      color: var(--light-gray);
    }
    
    .label-float {
      top: -10px;
      left: 8px;
      font-size: 10px;
      color: var(--text-color);
      padding: 0px 4px;
      z-index: 3;
    }
  }
  
  
  