#z2-admin .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  #z2-admin .trigger:hover {
    color: #1890ff;
  }
  
  #z2-admin .logo {
    height: 37px;
    // background: rgba(255, 255, 255, 0.3);
    margin: 16px;
    display: inline-flex;
    width: calc(100% - 32px);
    text-align: center;
    overflow: hidden;
  }
  #z2-admin .logo img,  #z2-admin .logo svg {
    height: 100%;
    margin: auto;
  }

  .ant-layout.site-layout {
    background: var(--bg-color);
    display: block;
    position: relative;
    overflow: auto;
  }
  .site-layout .z2-admin-header{
      background-color: transparent;
      padding: 0px 50px 0 30px;
      position: fixed;
      top: 0;
      // left: 0;
      width: calc(100% - 200px);
      z-index: 3;
      transition: background .3s;
      &.scrolled{
        background-color: var(--sidebar);
        box-shadow: 6px -2px 10px -3px rgba(0, 0, 0, 0.13);
      }
      &.expanded{
        width: calc(100% - 80px);
      }
  }