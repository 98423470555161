@import '~antd/dist/antd.css';
@import 'variables.scss';
@import 'ant-design.scss';
@import 'ant-overrides.scss';

// $blue: #1a1ac4;
// $lightblue: #d5dfee;
// $grey: #c3c4c9;
// $white: #ffffff;
// $red: #bc1535;
// $yellow: #ffd400;
// $darkgrey: #a2a4ab;

#root {
  height: 100%;
  font-size: 10px;
}
html,
body {
  font-family: 'Roboto', sans-serif;
  // font-weight: 500;
  font-size: 10px;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
h1 {
  font-size: 2.4rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.6rem;
}
h4,
p.small {
  font-size: 1.2rem;
}
p {
  font-size: 1.4rem;
}
.text-center {
  text-align: center;
}
.flex {
  display: flex;
}
.flex.flex-sb {
  justify-content: space-between;
}
.flex.flex-ac {
  align-items: center;
}
.flex.flex-jc {
  justify-content: center;
}
.flex.flex-col {
  flex-direction: column;
}
.flex .flex-as {
  align-items: flex-start;
}
.flex .flex-je {
  justify-content: flex-end;
}
.z2-container {
  background: var(--bg-color-2);
  // box-shadow: 2px 4px 7px 0px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  min-height: 10px;
  &.padded {
    padding: 30px;
  }
  &.padded-tb {
    padding: 30px 0;
  }
  &.padded-lr {
    padding: 0 30px;
  }
  &.padded-lrb {
    padding: 0 30px 30px 30px;
  }
  &.full {
    min-height: 100%;
  }
}
.z2-card {
  background: var(--bg-color-2);
  border-radius: 8px;
  min-height: 10px;
  box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.13);
  box-shadow: 2px 2px 8px -3px rgba(0, 0, 0, 0.23);
  position: relative;
  overflow: hidden;
  &.padded {
    padding: 20px;
  }
  &.padded-tb {
    padding: 20px 0;
  }
  &.padded-lr {
    padding: 0 20px;
  }
  &.padded-lrb {
    padding: 0 20px 20px 20px;
  }
  &.full {
    height: 100%;
  }
  &.no-shadow {
    box-shadow: none;
  }
  &.flex-1 {
    flex: 1;
  }
  &.bordered{
    border: 1px solid var(--border);
  }
  &.hover-shadow{
    transition: .5s;
    box-shadow: 2px 2px 8px -3px rgba(0, 0, 0, 0);
    &:hover{
      box-shadow: 2px 2px 8px -3px rgba(0, 0, 0, 0.23);
    }
  }
  .card-link {
    position: absolute;
    width: 100%;
    padding: 5px 10px;
    bottom: 0;
    left: 0;
    text-align: center;
    border-top: 1px solid var(--bg-color);
    font-size: 12px;
    background-color: var(--bg-color-2);
    &:hover {
      background-color: var(--bg-color);
    }
  }
}
.z2-collapse {
  // background: var(--bg-color-2);
  border-radius: 8px;
  // min-height: 10px;
  // box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.13);
  // box-shadow: 2px 2px 8px -3px rgba(0, 0, 0, 0.23);
  // position: relative;
  // overflow: hidden;
  overflow:visible
}
.text-link {
  color: currentColor;
  &:hover {
    color: var(--primary-color);
  }
}
.back-link {
  color: currentColor;
  display: inline-flex;
  align-items: center;
  svg{
    margin-right: 5px;
  }
  &:hover {
    color: var(--primary-color);
  }
}
.clipboard-input {
  width: 160px;
  padding: 3px 20px 3px 5px;
  background-image: url('../assets/svgs/copy.svg');
  background-position: calc(100% - 5px) 4px;
  background-repeat: no-repeat;
  background-size: 12px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  border-radius: 4px;
  color: var(--gray);
  font-size: 12px;
  background-color: var(--bg-color-2);
  text-overflow: ellipsis;
  overflow:hidden;
  &.center {
    text-align: center;
  }
  &:focus {
    border: 1px solid #c4c4c4;
    outline: none;
  }
  &:active {
    border: 1px solid #888;
  }
}

.isSticky {
  position: fixed;
  top: 80px;
  width: 230px;
}

// helper utils
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb0 {
  margin-bottom: 0px;
}
.margin0 {
  margin: 0px;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.text-align-right {
  text-align: right;
}
.hr-color {
  border-top: var(--bg-color);
}
.ml20 {
  margin-left: 20px;
}
.use-bg-color {
  background: var(--bg-color);
}
.pad-all-10 {
  padding: 10px;
}
.pad-all-20 {
  padding: 20px;
}
.pad-left-20 {
  padding-left: 20px;
}
.pad-right-20 {
  padding-right: 20px;
}
.pad-bottom-0 {
  padding-bottom: 0px;
}
.pad-top-0 {
  padding-top: 0px;
}
.pad-top-20 {
  padding-top: 20px;
}
.mr10 {
  margin-right: 10px;
}
.m0 {
  margin: 0px;
}
.ml10 {
  margin-left: 10px;
}
.spin-loader {
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  // border-radius: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #bbb;
  cursor: pointer;
  &:hover {
    background-color: #aaa;
  }
}

.filter-sidebar-header {
  position: relative;
  font-size: 18px;
  display: flex;
  span {
    cursor: pointer;
  }
}

.transition-hide {
  width: 0;
  padding: 0 !important;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
  flex: 0 !important;
  opacity: 0;
  height: 0;
  &.show {
    height: auto;
    opacity: 1;
    padding: inherit !important;
    transition: all 0.3s ease-in-out;
    flex: 1 !important;
  }
}
.pull-right {
  float: right;
}
.pointer-cursor {
  cursor: pointer;
}
.lightgray {
  color: var(--light-gray);
}
.width100 {
  width: 100%;
}
.noModalFooter{
  .ant-modal-footer{
    display: none;
  }
}
.word-break {
  word-break: break-word;
}
.pad-all-0 {
  padding: 0px !important;
}
.z2-divider-small.ant-divider-horizontal.ant-divider-with-text-left::before{
  width: 20px;
}
.z2-divider-small.ant-divider-horizontal .ant-divider-inner-text{
  padding: 0 0px;
}

// test
.selected-row {
  background-color: rgba(32, 114, 239, 0.13);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset;
  cursor: pointer;
}

.table-user-id {
  display: flex;
  width: 100%;
  align-items: flex-end;
  span:first-child {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span:last-child {
    display: none;
  }
}
.table-user-id:hover {
  span:first-child {
    width: 80%;
  }
  span:last-child {
    width: 20%;
    text-align: end;
    display: block;
    line-height: 1;
  }
}


// Custom css
.ant-card.active{
  border: 1px solid #2072EF;
}
.border{
  border: 2px solid #2072EF;
}
.ant-card-bordered{
  border-radius: 8px;
}
.ant-card{
  box-shadow: none;
  border: 1px solid #CCCCCC;
}
.hor-cards{
  cursor: pointer;
}
.hor-cards .ant-card-meta-avatar svg{
  width: 32px;
  height: 32px;
  fill: #2072EF;
}
.ant-card-body{
  padding-bottom: 14px !important;
}
.horizontal-scroll-col{
  display: flex;
  overflow-x: scroll;
  padding:10px 0px 10px 0 !important;
  margin: 0 10px !important;
}
.asset-bal{
  padding-left: 47px;
}
.ant-card-meta-title{
  font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
}
.current-bal{
  font-weight: 500;
font-size: 14px;
line-height: 17px;
color: #2072EF;
}
.bal-usd{
  font-weight: 500;
font-size: 14px;
line-height: 17px;
color: #888888;
}
.side-drawer-custom{
  position: absolute;
  width: 430px;
  right: 0;
  top: 0;
  overflow-y: scroll;
  height: calc(100vh - 130px);
  background: #fff;
}
.wallet-table-data-name{
  color: #2D2D2D;
  font-weight: 400;
font-size: 14px;
line-height: 19.59px;
margin-left: 10px;
}
.wallet-table-data-name span{
  color: #2D2D2D;
  font-weight: 400;
  margin-top: 12px;
font-size: 12px;
line-height: 11.72px;
}
.wallet-table-data-vol{
  color: #666666;
  font-weight: 500;
font-size: 14px;
line-height: 19.59px;
}
.wallet-table-data-vol span{
  color: #666666;
  font-weight: 400;
font-size: 12px;
line-height: 16.59px;
}
.wallet-cards{
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 8px;
  p{
    font-size: 18px;
    color: #2D2D2D;
    font-weight: 400;
    text-transform:capitalize;
    margin-bottom: 5px;
  }
  span{
    font-size: 14px;
    color: #2D2D2D;
    font-weight: 500;
  }
  .ant-card-body{
    padding-bottom: 24px !important;
    display: flex
  }
}






// @keyframes supportanimation {
//   from {
//    transform: translate(100%, 0);
//    opacity:0;
//   }
//   to {
//    transform: translate(0%, 0);
//    opacity: 1;
//   }
//  }
//  @keyframes supportanimationhide {
//   from {
//    transform: translate(0%, 0);
//    opacity:1;
//   }
//   to {
//    transform: translate(200%, 0);
//    opacity: 0;
//   }
//  }
// .side-drawer-custom.visible{
//   position: absolute;
//   padding: 30px;
//   right: -20px;
//   box-shadow: rgba(0, 0, 0, 0.13) ;
//   bottom: 0px;
//   transform: translate(0%, 0);
//   animation-iteration-count: 1;
//   animation-duration: .5s; /* instead of 5 sec */
//   animation-name: supportanimation;
//   opacity: 1; /* fade-in effect */
// }
.side-drawer-custom{
span{
  float: right;
  cursor: pointer;
}
h3{
  border-bottom: 1px solid #ccc;
  font-size: 24px;
  line-height: 28px;
  padding:0px 25px 20px 25px;
  text-transform: capitalize;
  span{
    font-size: 18px !important;
    font-weight: 500;
    line-height: 28px;
  }
}
ul{
  padding-left: 0;
  padding: 25px;
}
li{
  list-style: none;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
  span{
    font-weight: 500;
  }
}
}
// .side-drawer-custom.hidden{
//   opacity: 0;
//   z-index: -1;
//   animation-delay: 0s; /* instead of 1 sec */
//   animation-play-state: running;
//   animation-iteration-count: 1;
//   animation-duration: .5s; /* instead of 5 sec */
//   animation-name: supportanimationhide;
// }

/*side overlay*/
.side-drawer-custom {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 135px;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  padding-top: 30px;
  transition: 0.5s;
}
.side-drawer-custom a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s
}
.side-drawer-custom a:hover,
.side-drawer-custom .offcanvas a:focus {
  color: #f1f1f1;
}
.side-drawer-custom .closebtn {
  position: absolute;
  top: -35px;
  right: 25px;
  font-size: 55px;
  margin-left: 50px;
}
.hidden.side-drawer-custom{
  width: 0px;
}
.visible.side-drawer-custom{
  width: 400px;
  .ant-row{
    display: block;
    margin: 0 !important;
  }
}




.loginButtonCont{
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 300px;
  text-align: center;
  padding: 40px 0;
  width: 25%;
  border-radius: 6px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, .14);
  img{
    display: block;
    margin: 0 auto;
  }
  Button{
    margin-top: 60px;
  }
}
.ant-table-empty .ant-empty-normal{
  margin: 84px 0 !important;
}
.Reports-Cont{
  h3{
    display: inline;
  }

  display: block;
  margin-bottom: 5rem;
}
.assets-table .ant-card-meta-avatar svg{
  width: 32px;
  height: 32px;
  fill: #2072EF;
}
.vertical-mid svg{
  vertical-align: text-bottom;
  margin-right: 5px;
}
.wallet-coins {
display: flex;
justify-content: space-between;u
 svg,.ant-avatar-image{
  height: 20px;
  width: 20px;
}
}
.coin-icon-table{
  display: inline-flex;
  color: var(--primary-color);
}
.coin-icon-table svg{
  fill:var(--primary-color);
  width: 20px;
  height: 20px;
}
// .overview-inner-table tbody.ant-table-tbody{
// background-color: aliceblue !important;
// }
.filerSearchDropdown{
  padding: 10px;
  Input{
    margin-right: 5px;
  }
  Button{
    margin-right: 5px;
  }
  Button:last-child{
    margin-right: 0px;
  }
}
.ant-table-filter-column{
  margin: 0;
}
.ant-select-single .ant-select-selector .ant-select-selection-item{
  font-weight: 500 !important;
}
.ant-table-expanded-row.ant-table-expanded-row-level-1{
  >td.ant-table-cell{
    padding: 16px 64px;
  }
  h3{
    padding-bottom: 16px;
  }
}
.anticon.anticon-close-circle{
  height: 20px;
  svg{
  height: 19px;
  width: 19px;
  margin-top: -5px;
}
}