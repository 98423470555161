
:root {
  --bg-color: #f3f4f5;
  --bg-color-2: #fff;
  --bg-color-3: #fafafa;
  --text-color: #2d2d2d;
  --primary-color: #2072EF;
  --primary-dark: #0f5acd;
  --primary-light: rgb(0 123 255 / .13);
  --primary-hover:#6ca2f4;
  --primary-light-opaque: rgb(209 227 246);
  --gray: #666666;
  --light-gray: #888888;  
  --sidebar: #fff;  
  --red: #f5222d;
  --border: #d8d8d8;
}
.theme-z1{
  --primary-color: #2072EF;
  --primary-dark: #0f5acd;
  --primary-light: rgb(0 123 255 / .13);
}
.theme-z2{
  --primary-color: #502E94;
  --primary-dark: #3b226d;
  --primary-light: #653abb;
}
.theme-dark{
  --primary-color: #2072EF;
  --primary-dark: #0f5acd;
  --primary-light: rgb(0 123 255 / .13);
  --bg-color: #2D2D2D;
  --bg-color-2: #232323;
  --bg-color-3: #706d6d;
  --text-color: #f3f4f5;
  --gray: #888888;
  --light-gray: #C4C4C4;  
  --sidebar: #131313;  
  .z2-card{
    background-color: var(--bg-color)
  }
  a {
    color: #4C97E9;
  }
}

$primary-color: var(--primary-color); // primary color for all components
$primary-light: var(--primary-light-opaque); // primary color for all components
$primary-dark: var(--primary-dark); // primary color for all components
$link-color: var(--primary-color); // link color
$success-color: #52c41a; // success state color
$warning-color: #faad14; // warning state color
$error-color: #f5222d; // error state color
$font-size-base: 14px; // major text font size
$heading-color: rgba(0, 0, 0, 0.85); // heading text color
$text-color: rgba(0, 0, 0, 0.65); // major text color
$text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
$disabled-color: #b4b5b8; // disable state color
// $disabled-color: rgba(0, 0, 0, 0.25); // disable state color
$border-radius-base: 2px; // major border radius
$border-color-base: #d9d9d9; // major border color
$box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layerss


$black: #2d2d2d;
$mid-gray: #666;
