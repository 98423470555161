

  .zeb-sider{
    background-color: var(--sidebar);
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
      background-color:var(--primary-light)
    }
    .ant-menu-item a,
    .ant-menu-submenu,
    .ant-menu-inline .ant-menu-item{
      color: var(--text-color);
    }
      .ant-menu-inline .ant-menu-item,
      .ant-menu-submenu{
        
        width: calc(100% - 24px);
        border-radius: 8px 0px 0px 8px;
        margin-left: auto;
        .ant-menu-submenu{
          .ant-menu-item{
            width: 100%;
          }
          .ant-menu-submenu-title{
            padding-left: 30px;
          }
        }
        &.ant-menu-submenu-selected{
          color: var(--primary-color);
        }
      }
      .ant-menu-inline-collapsed .ant-menu-item-selected::after,
      .ant-menu-inline .ant-menu-item-selected::after{
        transform: scaleY(1);
        opacity: 1;
        transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      .ant-menu-submenu svg,
      .ant-menu-item svg{
        margin-right: 8px ;
        margin-bottom: -2px;
      }
      // .ant-menu-item-only-child{
      //   padding-left: 30px !important;
      // }
      .ant-menu-inline .ant-menu-submenu-title {
          border-radius: 8px 0 0 8px;
      }
      .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title{
        padding-left: 0;
      }
      .ant-menu-inline-collapsed > .ant-menu-item,  
      .ant-menu-inline-collapsed .ant-menu-submenu{
        padding-left: 14px;
        width: calc(100% - 16px);
        border-radius: 8px 0px 0px 8px;
        margin-left: auto;
      }
      .logout-menu{
        position: absolute;
        bottom: 30px;
        left: 24px;
      }
      .ant-menu-inline-collapsed .logout-menu{
        left: 14px;
      }
  }
  .ant-btn{
      border-radius: 20px;
  }
  .ant-input {
    // &.ant-input-sm{
    //   padding: 0 7px;
    // }
    &.ant-input-lg{
      padding: 10px 12px 8px 11px;
    }
    
  }
  
  // .ant-select .ant-select-selector {
  //   padding: 10px 10px 8px 11px;
  // }
  
  // .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  //   padding: 10px 10px 8px 11px;
  //   height: 48px;
  // }
  
  .custom-select  .ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 10px;
    padding-left: 21px;
  }
  .ant-input-group-addon{
    background-color: var(--bg-color);
  }
  // ant tabs
  .z2-user-tabs{
      .ant-tabs-nav{
          margin: 0;
      }
    >div>  .ant-tabs-content{
        padding: 30px;
      }
  }

  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab{
    padding: 16px 30px;
    font-size: 14px;
    margin-right: 0;
  }
  .ant-tabs{
    overflow: visible;
  }
  .ant-tabs-tab {
    margin-right: 20px;
    color: var(--gray);
    &.ant-tabs-tab-active{
      color: var(--primary-color);
    }
    .ant-tabs-tab-btn{
      line-height: 1;
    }
  }
  // tags
  .ant-tag{
    border-radius: 4px;
    padding: 0 12px;
  }
  .ant-form-item-label > label{
    display: inline-flex;
    align-items: center;
    height: 14px;
    font-weight: 500;
    color: var(--gray);
    font-size: 12px;
  }
  /* search table withdrawal screen */
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
      font-size: 12px;
      // padding: 16px 8px;
  }

  // ant table 
  .ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title{
    padding: 8px 2em 8px 8px;
  }
  .ant-table-filter-trigger{
    width: 2em;
  }
  .ant-table-pagination.ant-pagination{
    padding: 0 20px;
  }
  /* reducing the z-index after it creates issues for header */
  .ant-table-cell-fix-left {
    z-index: 1;
  }

  /* reducing amount sidebar width */
  .ant-slider {
    width: 90%;   
  }
.ant-slider-mark{
  display: flex;
  justify-content: space-between; 
  width: calc(100% + 10px);
  font-size: 12px;
  margin-left: -5px;
}
.ant-slider-mark-text{
  position: relative;
  left: 0  !important;
  transform: none !important;
}
// Collapse
.z2-collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
  top: 9px;
}
.z2-collapse{
  border-radius: 8px;
  min-height: 10px;
  position: relative;
  overflow: hidden;
  .ant-collapse-item{
    background: var(--bg-color-2);
    box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.13);
    box-shadow: 2px 2px 8px -3px rgba(0, 0 ,0 , .23);
    border-radius: 8px;
    margin-bottom:30px;
    &:last-child{
      border-radius: 8px;
    }
    .ant-collapse-header{
      border-radius: 8px 8px 0 0;
      transition: .3s;
      transition-delay: .3s;
      border-bottom: 1px solid transparent;
      .ant-collapse-arrow.z2-collapse-icon{
          position: absolute;
          top: 12px;
          transform-origin: 7px 19px;
          transition: .3s;
          &.rotate{
          transform: rotate(90deg);
          }
        }
    }
    .ant-collapse-content{
      .ant-collapse-content-box{
       padding: 20px;
     }
    }
    &.ant-collapse-item-active{
      border: 1px solid var(--primary-color);
    }
  }
  .ant-collapse-item.ant-collapse-item-active{
    .ant-collapse-header{
      border-bottom: 1px solid var(--border);
    }
  }
  &.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header{
    padding: 12px 20px;
    padding-right: 80px;
  }
}

// dropdown
.ant-dropdown-menu{
  border-radius: 4px;
}

// .ant-select-lg > div {
//   height: 38px !important;
// }

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: var(--bg-color-3) !important;
}

.title-select{
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    padding-left: 0;
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
      outline: none;
      box-shadow: none;
  }

  &.ant-select-show-search .ant-select-selector{
    padding: 0;
      border: none;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }
  &.ant-select-single .ant-select-selector .ant-select-selection-search{
      left: 0;
  }
  .ant-select-arrow{
    top: 14px;
  }
}