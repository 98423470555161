.loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #502E94;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    z-index: 10;
    .loaderMask{
      width: 30px;
      height: 30px;
      overflow: hidden;
    }
    .loaderIconSprite{
      transform-origin: top;
      animation: loader 3s ease-in-out  infinite;
    }
    .loaderTtext{
      margin-top: 20px;
    }

    &.basic{
        background-color: rgba(255,255,255,.8);
        color: var(--primary-color);
        img{
          display: none;
        }
    }
}

@keyframes loader{
    0%{
      transform: translateY(0px)  scale(1);
      opacity: 1;
    }
    10%{
      transform:  scale(0);
      opacity: 0;
    }
    20%{
      transform: translateY(-35px) scale(1);
      opacity: 1;
    }
    30%{
      transform:  scale(0);
      opacity: 0;
    }
    40%{
      transform: translateY(-67px) scale(1);
      opacity: 1;
    }
    50%{
      transform:  scale(0);
      opacity: 0;
    }
    60%{
        transform: translateY(-103px) scale(1);
        opacity: 1;
    }
    70%{
      transform:  scale(0);
      opacity: 0;
    }
    80%{
        transform: translateY(-137px) scale(1);
        opacity: 1;
    }
    90%{
      transform:  scale(0);
      opacity: 0;
    }
    100%{
      transform: translateY(0px)  scale(1);
      opacity: 1;
    }
  }