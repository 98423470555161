@import '../../../styles/variables.scss';
p.text {
    margin-bottom: 6px;
    line-height: 1;
    font-weight: 500;
    // margins
    color: var(--gray);

    &.xs {
        margin-bottom: 8px;
    }
    &.sm {
        margin-bottom: 20px;
    }
    &.md {
        margin-bottom: 30px;
    }
    &.lg {
        margin-bottom: 40px;
    }
    &.xl {
        margin-bottom: 60px;
    }
    &.none {
        margin-bottom: 0;
    }
    // colors
    &.dark {
        color: var(--text-color);
    }
    &.light {
        color: #fff;
    }
    &.red{
        color: $primary-color;
    }
    &.gray{
        color: $mid-gray;
    }
    &.yellow{
        color: #E7DF29;
    }
    &.green{
        color: #29BD1C;
    }
    // Alignment
    &.right {
        text-align: right;
    }
    &.left {
        text-align: left;
    }
    &.center {
        text-align: center;
    }

    // letter styling
    &.capitalize {
        text-transform: capitalize;
    }
    // size
    &.fontsm {
        font-size: 1.2rem;
    }
    &.fontmd {
        font-size: 1.4rem;
    }
    &.fontlg {
        font-size: 1.6rem;
    }

    // // background
    // &.yellow {
    //     background: rgba(231, 223, 41, 0.25);
    // }
    // &.green {
    //     background: rgba(41, 189, 28, 0.26);
    // }
    // &.red {
    //     background: rgba(218, 47, 17, 0.26);
    // }

    // // border
    // &.sm {
    //     border: 1px solid;
    // }
    // &.md {
    //     border: 2px solid;
    // }
    // &.lg {
    //     border: 3px solid;
    // }
    // &.none {
    //     border: none;
    // }
    // // border color
    // &.yellow {
    //     border-color: #E7DF29;
    // }
    // &.green {
    //     border-color: #29BD1C;
    // }
    // &.red {
    //     border-color: red;
    // }

    // // border radius
    // &.sm {
    //     border-radius: 4px;
    // }
    // &.md {
    //     border-radius: 8px;
    // }
    // &.lg {
    //     border-radius: 16px;
    // }
}