.anchor{
    display: inline-flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1;
    &.italics{
        font-style: italic;
    }
    &.underlined{
        text-decoration: underline;
    }    
    .iconBefore{
        margin-right: 5px;
        display: inline-flex;
    }
    .iconAfter{
        display: inline-flex;
        margin-left: 5px;
    }
}