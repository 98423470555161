.headerOptions {
  // width: 130px;
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 30px;
  }
  .user {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 10px;
    }
    .name {
      font-size: 1.4rem;
      color: var(--light-gray);
    }
  }
}
.searchResultHover {
  a {
    color: inherit !important;
  }
  a:hover {
    color: var(--gray);
  }
}
.removeSidePadding > div {
  padding: 0px !important
}
.removeSidePadding > div > span > span > span > span {
  display: none;
}
