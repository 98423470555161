.closed{
   transform: translateY(58px);
}
.opened{
   transform: translateY(0);
}
.balanceBar{
    box-shadow: 18px 8px 14px 2px rgba(0,0,0 ,0.13);
    padding: 20px 40px;
    transition: .3s;
}
.icon{
    padding: 5px;
    position: absolute;
    bottom: calc(100% - 10px);
    left: 50%;
    transform: translateX(-50%);
    background: var(--bg-color-2);
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 2px -2px 4px 0px rgba(0,0,0 ,0.13);
    width: 21px;
    height: 21px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
    border: 1px solid var(--light-gray);
    svg{
        transition: .3s;
    }
    &.closed{
        transform: translate(-50%,-10px);
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        svg{
            transform: rotate(180deg);
        }
    }
}